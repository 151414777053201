<template>
  <div id="robot-view">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important; display: flex">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-cloud-lightning"
          @click="onGenerateModelClick"
          :disabled="isProcessing"
          >{{ $l.get("robot-view.generate-model") }}</vs-button
        >
        <vs-button
          style="margin-left: 5px"
          type="border"
          icon="cached"
          @click="reload"
          >{{ $l.get("dictionary.refresh") }}</vs-button
        >
        <vs-button
          style="margin-left: 5px"
          type="border"
          icon-pack="feather"
          icon="icon-edit"
          @click="onEditClick"
          >{{ $l.get("dictionary.edit") }}</vs-button
        >
      </div>
      <br />
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("robot-view.progress") }}</h3>
            </div>
            <vue-apex-charts
              type="radialBar"
              height="300"
              :options="chartOptions"
              :series="trainingProgressChartSeries"
            />
          </vs-card>
        </div>
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <vs-card>
            <div slot="header">
              <h3>{{ $l.get("robot-view.rate") }}</h3>
            </div>
            <vue-apex-charts
              type="radialBar"
              height="300"
              :options="chartOptions"
              :series="trainingRateChartSeries"
            />
          </vs-card>
        </div>
      </div>
      <br />
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("robot-view.samples") }}</h3>
        </div>
        <vs-table v-if="data" :data="data.samples">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{ $l.get("dictionary.name") }}</vs-th>
            <vs-th v-if="containsSpecificity">{{
              $l.get("robot-view.specificity")
            }}</vs-th>
            <vs-th>{{ $l.get("robot-view.score") }}</vs-th>
            <vs-th v-if="containsSpecificity">{{
              $l.get("robot-view.specificity-score")
            }}</vs-th>
          </template>

          <template>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data.samples">
              <vs-td>{{ tr.user.id }}</vs-td>
              <vs-td>{{ tr.user.name }}</vs-td>
              <vs-td v-if="containsSpecificity">
                <vs-button
                  :color="tr.specificity == 0 ? 'dark' : 'warning'"
                  type="gradient"
                  icon="star"
                  @click="onSampleSpecificityClick(tr)"
                ></vs-button>
              </vs-td>
              <vs-td>{{ tr.score }}%</vs-td>
              <vs-td v-if="containsSpecificity">
                {{ getSpecificityScore(tr.specificitiesScores, 1) }}%
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="filled"
                @click="onSampleAddClick"
                >{{ $l.get("dictionary.add") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
      <br />
      <vs-card>
        <div slot="header">
          <h3>{{ $l.get("robot-view.tests") }}</h3>
        </div>
        <vs-table v-if="data" :data="data.tests">
          <template slot="thead">
            <vs-th>#</vs-th>
            <vs-th>{{ $l.get("dictionary.name") }}</vs-th>
            <vs-th>{{ $l.get("robot-view.score") }}</vs-th>
            <vs-th v-if="containsSpecificity">{{
              $l.get("robot-view.specificity-score")
            }}</vs-th>
            <vs-th>{{ $l.get("dictionary.process") }}</vs-th>
          </template>

          <template>
            <vs-tr
              :state="getTestState(tr)"
              :key="indextr"
              v-for="(tr, indextr) in data.tests"
            >
              <vs-td>{{ tr.user.id }}</vs-td>
              <vs-td>{{ tr.user.name }}</vs-td>
              <vs-td>{{ tr.score }}%</vs-td>
              <vs-td v-if="containsSpecificity">
                {{ getSpecificityScore(tr.specificitiesScores, 1) }}%
              </vs-td>
              <vs-button
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-cloud-lightning"
                @click="onProcessUserReportClick(tr)"
              ></vs-button>
            </vs-tr>
          </template>
        </vs-table>
        <vs-divider />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="filled"
                @click="onTestAddClick"
                >{{ $l.get("dictionary.add") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
        <br />
        <div class="expanded">
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-offset="3"
              vs-w="6"
            >
              <vs-button
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-cloud-lightning"
                @click="onTestProcessAllClick"
                >{{ $l.get("dictionary.process-all") }}</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </div>
    <UserFindPrompt
      :isOpen.sync="isUserPromptOpen"
      :onClose="onUserPromptClose"
    />
  </div>
</template>

<script>
import SelectRobotSampleSpecificityInteractor from "@/business/robot-view/select-robot-sample-specificity";
import SelectRobotTestProcessAllInteractor from "@/business/robot-view/select-robot-test-process-all";
import RefreshProgressInteractor from "@/business/robot-view/refresh-progress";
import SelectRobotTestProcessInteractor from "@/business/robot-view/select-robot-test-process";
import SelectRobotEditInteractor from "@/business/robot-view/select-robot-edit";
import SelectGenerateModelInteractor from "@/business/robot-view/select-generate-model";
import SubmitNewRobotTestInteractor from "@/business/robot-view/submit-new-robot-test";
import SubmitNewRobotSampleInteractor from "@/business/robot-view/submit-new-robot-sample";
import InitRobotViewInteractor from "@/business/robot-view/init-robot-view";
import RobotViewScreenController from "@/adapters/controllers/screen-robot-view";

import Loader from "@/application/components/Loader";
import UserFindPrompt from "@/application/components/UserFindPrompt";
import VueApexCharts from "vue-apexcharts";

export default {
  screen_name: "robot-view",
  components: { Loader, UserFindPrompt, VueApexCharts },
  data() {
    return {
      controller: null,
      interactors: {
        selectRobotSampleSpecificity: null,
        selectRobotTestProcessAll: null,
        refreshProgress: null,
        selectRobotTestProcess: null,
        selectRobotEdit: null,
        selectGenerateModel: null,
        submitNewRobotTest: null,
        submitNewRobotSample: null,
        initRobotView: null
      },
      data: null,
      isLoading: false,
      isUserPromptOpen: false,
      isToAddUserToSamples: false,
      isToAddUserToTests: false,
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%"
            },
            track: {
              background: "#bfc5cc",
              strokeWidth: "50%"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 18,
                color: "#99a2ac",
                fontSize: "4rem"
              }
            }
          }
        },
        colors: ["#00db89"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#00b5b5"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(RobotViewScreenController);

    //{ INTERACTORS
    this.interactors.initRobotView = this.$injector.get(
      InitRobotViewInteractor
    );
    this.interactors.submitNewRobotSample = this.$injector.get(
      SubmitNewRobotSampleInteractor
    );
    this.interactors.submitNewRobotTest = this.$injector.get(
      SubmitNewRobotTestInteractor
    );
    this.interactors.selectGenerateModel = this.$injector.get(
      SelectGenerateModelInteractor
    );
    this.interactors.selectRobotEdit = this.$injector.get(
      SelectRobotEditInteractor
    );
    this.interactors.selectRobotTestProcess = this.$injector.get(
      SelectRobotTestProcessInteractor
    );
    this.interactors.refreshProgress = this.$injector.get(
      RefreshProgressInteractor
    );
    this.interactors.selectRobotTestProcessAll = this.$injector.get(
      SelectRobotTestProcessAllInteractor
    );
    this.interactors.selectRobotSampleSpecificity = this.$injector.get(
      SelectRobotSampleSpecificityInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.reload = this.reload;
  },
  mounted() {
    this.reload();
    this.refreshProgress();
  },
  computed: {
    isProcessing() {
      return this.data && this.data.robot && this.data.robot.tasksCount > 0;
    },
    containsSpecificity() {
      return (
        this.data &&
        this.data.robot &&
        this.data.robot.settingsSpecificities > 0
      );
    },
    trainingProgressChartSeries() {
      if (this.data && this.data.robot) {
        if (this.data.robot.trainingProgress == 0) return [0];
        return [this.data.robot.trainingProgress.toFixed(2)];
      } else {
        return [0];
      }
    },
    trainingRateChartSeries() {
      if (this.data && this.data.robot) {
        if (this.data.robot.trainingRate == 0) return [0];
        return [this.data.robot.trainingRate.toFixed(2)];
      } else {
        return [0];
      }
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    reload() {
      const id = this.$route.params.id;
      this.interactors.initRobotView.handle(id);
    },
    refreshProgress() {
      const id = this.$route.params.id;
      const isDataEmpty = !this.data || !this.data.robot;
      if (this.isProcessing || isDataEmpty) {
        const _ = this;
        setTimeout(() => {
          if (isDataEmpty) {
            _.refreshProgress();
          } else {
            _.interactors.refreshProgress.handle(id).then(_.refreshProgress);
          }
        }, 5000);
      }
    },
    onUserPromptClose(user) {
      const id = this.$route.params.id;
      if (this.isToAddUserToSamples) {
        this.interactors.submitNewRobotSample.handle({
          robot: id,
          userId: user.id
        });
      } else if (this.isToAddUserToTests) {
        this.interactors.submitNewRobotTest.handle({
          robot: id,
          userId: user.id
        });
      }
      this.isUserPromptOpen = false;
      this.isToAddUserToSamples = false;
      this.isToAddUserToTests = false;
    },
    onSampleAddClick() {
      this.isUserPromptOpen = true;
      this.isToAddUserToSamples = true;
    },
    onTestAddClick() {
      this.isUserPromptOpen = true;
      this.isToAddUserToTests = true;
    },
    onGenerateModelClick() {
      this.interactors.selectGenerateModel.handle(this.data.robot);
      setTimeout(this.refreshProgress, 10000);
    },
    onEditClick() {
      this.interactors.selectRobotEdit.handle(this.data.robot);
    },
    onProcessUserReportClick(robotTest) {
      this.interactors.selectRobotTestProcess.handle(robotTest);
    },
    onTestProcessAllClick() {
      this.interactors.selectRobotTestProcessAll.handle();
    },
    onSampleSpecificityClick(robotSample) {
      if (robotSample.specificity == 0) {
        robotSample.specificity = 1;
      } else {
        robotSample.specificity = 0;
      }
      this.interactors.selectRobotSampleSpecificity.handle(robotSample);
    },
    getSpecificityScore(obj, idx) {
      if (obj && obj[idx]) {
        return obj[idx];
      } else {
        return 0;
      }
    },
    getTestState(tr) {
      if (this.getSpecificityScore(tr.specificitiesScores, 1) > 55) {
        return "warning";
      } else if (tr.score > 55) {
        return "success";
      } else {
        return null;
      }
    }
  }
};
</script>

<style lang="scss"></style>
